@import '~bootstrap/scss/carousel';

.quiz-slide {
  &.active {
    min-height: 450px;

    @include media-breakpoint-up(md) {
      min-height: 400px;
    }
  }
}

.quiz-step {
  @include box(44px);
}

.quiz-result-video-spinner {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-ghc-dental-quiz] {
  .custom-radio,
  .custom-checkbox {
    border-color: $gray-40;
  }
}

.carrierbenefits-fi2025-zipcode-tool, .carrierbenefits-aso2025-zipcode-tool {
  #zipcode-lookup-results {
    display: none;
  }
}
