#hsa-slider-module {
  p {
    font-size: 1.35rem;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .noUi-value {
    font-size: 16px;
  }

  .noUi-tooltip {
    padding: 0.35rem;
  }

  .slider-holder {
    height: calc(50px + 2rem);
    margin: calc(50px + 2rem) $spacing-m 0;

    @include media-breakpoint-up(md) {
      margin: calc(50px + 2rem) $spacing-xl 0;
    }
  }

  #total {
    font-weight: 700;
  }

  #preventive-care-slider .noUi-pips {
    display: none;
  }
}
