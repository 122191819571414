//
// Zip code lookup styling
//

.input-holder.input-success,
.input-holder-r.input-success {
  .validation-icn {
    right: $spacing-m;
  }
}

.product-cards {
  margin-bottom: $spacing-xl;
}

.product-card {
  margin-bottom: $spacing-ml;
}

[data-coverage-type] {
  display: none;

  &.show {
    display: block;
  }
}

#provider-promo-content {
  display: none;
}
