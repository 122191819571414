// Floating photo issue fix
//

.hero-floating-photo {
  @include media-breakpoint-up(xl) {
    bottom: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    transform: inherit;
  }
}

.universal-navigation {
  @include media-breakpoint-up(xl) {
    border-bottom: inherit;
  }
}
